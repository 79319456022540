<template>
  <div id="app"
  tabIndex="0"
  @mouseup="mouseup"
  @keyup="keyup"
  @keydown="keydown"
  >
  <Loader 
  :visible="loaderVisible"
  :appVersion="$appGlobals.appVersion"
  :message="loaderMessage"  
  :spinVisible="loaderSpinVisible">
  </Loader>   
  <template v-if="isAdmin">
  <Admin>    
  </Admin>
  </template>
  <template v-if="!isAdmin && isLoaded">
  <WaitDialog     
  ref="waitDialog"
  :spinner="waitSpinner"
  >
  <div class="d-flex flex-column">
    <div class="d-flex align-items-center">   
      <font-awesome-icon icon="map-marked" class="mr-2"/>             
        {{waitDialogMessage}}
    </div>            
  </div>
  </WaitDialog>   
  <OKDialog    
    ref="whatsNewDialog"
    @hidden="whatsNewHidden"
    >
      <template slot="title">
        Whats new!?
      </template>      
      <p> - LayerInfo / Metadata now available in-app. Click on the 'i' (info) button beside a layer name to show.</p>            
      <p> - 'Select all drawn items' toolbar button replaced with 'Delete all drawn items'.</p>      
      <p> - 'UK Power Networks' layers now available.</p>            
      <p> Keep up-to-date with all things GIS/Mapping Browser related, by refering to our <a href="https://norfolkcounty.sharepoint.com/sites/norfolk-mapping-browser" target="_blank">Sharepoint site</a> !
      </p>
      <p style="text-align:right"> - Your friendly GIS team <a href="mailto:GIS@norfolk.gov.uk">(GIS@norfolk.gov.uk)</a> </p>
    </OKDialog>   
    <Slidable
    :slideTimeMs="275"
    from="left">
      <ProjectMenu      
      id="projectMenu"
      v-if="projectMenuOpen"      
      @exportScreenshot="exportScreenshot"
      @exportProjectFile="downloadProject"
      @exportPresented="exportPresented"
      @close="projectMenuOpen = false">        
      </ProjectMenu>    
    </Slidable>
    <div class="appContent h-100"
    :class="{ blurred : projectMenuOpen }"
    >
      <UserProfile      
      @signOut="signOut"
      @openMenuItem="openMenuItem" 
      @closeMenuItem="closeMenuItem">
      </UserProfile>  
      <MappingBrowser 
      :arcGISBaseUrl="arcGISBaseUrl"
      :msal="msalService"
      :networkInterceptors="networkInterceptors"
      ref="mappingBrowser"            
      :mapExtent="mapExtent"
      :useExtendedScales="useExtendedScales"
      :canCreateLayers="canCreateLayers"
      :menuItems="menuItems"
      :componentsLeft="componentsLeft"
      :componentsTop="componentsTop"
      :componentsRight="componentsRight"                    
      :componentsBottom="componentsBottom"       
      :isFullScreen="isFullScreen"      
      :showLegendSymbologyInLayerManager="true"      
      :basemaps="basemaps"
      :layersConfig="layers"   
      :searches="searches"   
      :bookmarksThumbNailSourceUrl="bookmarksThumbNailSourceUrl"      
      :helpUrlBase="helpUrlBase"        
      @onBasemapChange="onBasemapChange"
      @setFullScreen="setFullScreen"
      @openMenuItem="openMenuItem" 
      @closeMenuItem="closeMenuItem"
      @initCancelled="initCancelled" 
      @loaded="onLoaded"
      >
      </MappingBrowser>      
     
      <TopMenu
      :menuItems="menuItems"
      :restoreState="doRestoreState"      
      :fromLocal="doRestoreFromLocal"
      :saveState="doSaveState"        
      @projectButtonClicked="projectMenuOpen = !projectMenuOpen"      
      @openMenuItem="openMenuItem" 
      @closeMenuItem="closeMenuItem" 
      @restoreStateEnabled="restoreStateEnabled"            
      @saveStateEnabled="saveStateEnabled"
      @restoreFromLocalOnLoad="restoreFromLocalEnabled"
      /> 
      <LeftMenu
      :menuItems="menuItems"
      @openMenuItem="openMenuItem" 
      @closeMenuItem="closeMenuItem" 
      />
      <BottomMenu
      :menuItems="menuItems"
      @openMenuItem="openMenuItem" 
      @closeMenuItem="closeMenuItem" 
      />
      <MenuContainer
      ref="menuContainer"
      :leftMenu="leftMenuItem"
      :topMenu="topMenuItem"
      :rightMenu="rightMenuItem"
      :bottomMenu="bottomMenuItem"
      :slideTimeMs="275"
      :leftMenuBarSize="48"
      :topMenuBarSize="48"
      :rightMenuBarSize="48"
      :bottomMenuBarSize="28"
      :leftMenuShown="leftMenuShown"
      :topMenuShown="topMenuShown"
      :rightMenuShown="rightMenuShown"
      :bottomMenuShown="bottomMenuShown"      
      >
      </MenuContainer>
    </div> 
   </template>     
  </div>
</template>

<script>
import Vue from "vue";
import MsalService from './shared/msal.js';
import MSGraphService from './msgraph';
import MBAPIService from './shared/mbapi.js';
import { gsap } from "gsap";
import { default as QueuePromise } from 'queue-promise';

import Loader from "./Loader.vue";
import MappingBrowser from "./MappingBrowser.vue";
import ProjectMenu from "./ProjectMenu.vue";
import UserProfile from "./UserProfile.vue";
import MenuContainer from "./shared/MenuContainer.vue"
import LeftMenu from "./menus/LeftMenu.vue"
import TopMenu from "./menus/TopMenu.vue"
import BottomMenu from "./menus/BottomMenu.vue"
import { mixins } from 'vue-class-component';
import { default as appPersistance } from "./AppPersistance.vue"
import OKDialog from './shared/OKDialog.vue';
import NetworkInterceptors from "./shared/NetworkInterceptors.js"
import layersConfig from "./json/layersConfig.json";
import axios from 'axios';
import pako from 'pako'
import WaitDialog from '@/components/shared/WaitDialog.vue';
import Moment from 'moment';
import Admin from './Admin/Admin.vue';
import { loadModules } from "esri-loader";

export default {
  name: "App",
  components: { MappingBrowser,Admin, ProjectMenu, UserProfile, Loader, MenuContainer, LeftMenu, TopMenu, BottomMenu, OKDialog, WaitDialog },  
  mixins: [ appPersistance ],
  data() {    
    return {  
      isAdmin: false,
      shiftDown: false,
      loaderVisible: true,
      clientInfo: {},
      waitDialogMessage: "",
      waitSpinner: false,                  
      initRestoreFailed: false,      
      showWhatsNew: true,      
      broadcastChannel: null,
      alreadyRunning: false,      
      useExtendedScales: false,      
      canCreateLayers: false,
      deviceSettings: null,      
      mapExtent: null,      
      loaderSpinVisible: true,      
      layers: null,
      searches: null,                  
      loaderMessage: "",
      loaderMainMessage: "",
      isLoaded: false, 
      msalService:null,      
      networkInterceptors: null,      
      arcGISTokens:null,      
      bookmarksThumbNailSourceUrl: "",
      arcGISBaseUrl: "",
      basemaps: null,      
      isFullScreen: false,      
      componentsLeft: 0,
      componentsTop: 0,
      componentsRight: 0,
      componentsBottom: 0,      
      slideTime: 0.275,
      leftMenuShown: true,
      topMenuShown: true,
      rightMenuShown: true,
      bottomMenuShown: true,
      projectMenuOpen: false,
      doRestoreState: true,
      doRestoreFromLocal: false,            
      doSaveState: true,   
      tokenTimeoutMins: 50,
      helpUrlBase:"",      
      menus: {
        name: "root",
        menus: 
        [
          {
          name: "right",
          openItem: null,
          isResizable: false,
          size: 0,          
          visibleSize: 0,         
          menus:
          [                         
            {
              name: "legend",              
              isResizable: false,              
              size: 200,                       
              visibleSize: 0,                                                                                      
            },   
            {
              name: "settings",              
              isResizable: false,              
              size: 300,                       
              visibleSize: 0,                                                                                      
            },                                  
            {
              name: "help",
              color: "#722",
              isResizable: false,
              minSize: 300,
              maxSize: 300,
              size: 400,                       
              visibleSize: 0,                                                                                    
            },
            {
              name: "userProfile",
              color: "#722",
              isResizable: false,              
              size: 400,                       
              visibleSize: 0,                                                                                    
            },
          ] 
        }, 
        {
          name: "bottom",
          openItem: null,
          isResizable: false,
          size: 24,          
          visibleSize: 0,    
          expandGripChild: "results",      
          menus:
          [
            {
              name: "results",            
              openItem: null,
              isResizable: true,
              minSize: 100,
              maxSize: 750,
              size: 250,          
              visibleSize: 0,                   
            }
          ]
        },
        {
          name: "top",
          openItem: null,
          isResizable: false,
          size: 48,          
          visibleSize: 0,          
        },                              
        {      
          name: "left",
          openItem: "search",
          isResizable: false,
          size: 48,          
          visibleSize: 0,
          
          menus:
          [
            {
              name: "search",
              color: "#722",
              isResizable: false,
              size: 375,                       
              visibleSize: 0,                                                                                    
            },
            {
              name: "layerManager",
              openItem: null,       
              isResizable: true,              
              minSize: 320,
              maxSize: 600,
              size: 320,                       
              visibleSize: 0,                 
            },
            {
              name: "basemapManager",
              openItem: null,
              isResizable: false,
              size: 300,                      
              visibleSize: 0,                     
            },
            {
              name: "bookmarkManager",
              openItem: null,      
              isResizable: false,
              size: 320,                      
              visibleSize: 0,                     
            },  
            {
              name: "properties",              
              isResizable: true,              
              size: 370,              
              minSize: 180,         
              visibleSize: 0,                                                                                      
            },           
          ],                 
        }],
      },          
    }
  },       

  async created() {            

    this.$set(this.$root, 'mbUser', {
      displayName: null,
      userPrincipalName: null,
      jobTitle: null,
      photoURL: null,
      roles: null,
      userId: null
    });          

    try {                 
      this.updateAppIcon();                
      
      this.clientInfo = this.getClientInfo();
      console.log("CLIENT INFO:")      
      console.log(this.clientInfo);

      this.msalService = new MsalService(import.meta.env.VITE_MSAL_ClientId, import.meta.env.VITE_MSAL_Authority);
      
      if(!this.msalService.isAuthenticated())
      {
        this.loaderMessage = "Wait! Who goes there?!"
    
        // remember parameters, as azure doesnt like passing them as a query string
        localStorage.redirectUrl = location.href;                
        this.msalService.signIn();

        // A redirect will happen here so app will run from start
      }
      else
      {
        //init MS Graph and get user details.
        const graphService = new MSGraphService(this.msalService);
        this.user.graphUser = await graphService.getUser();         
        this.user.displayName = this.user.graphUser.displayName,
        this.user.userPrincipalName = this.user.graphUser.userPrincipalName,
        this.user.jobTitle = this.user.graphUser.jobTitle,
        this.user.photoURL = this.user.graphUser.photo ? URL.createObjectURL(this.user.graphUser.photo) : null;

        if (localStorage.redirectUrl) {
          window.location.href = localStorage.redirectUrl;
          localStorage.removeItem("redirectUrl");
        }

        // check for admin link here...               

        this.loaderMessage = `Starting up for ${this.user.displayName} (${this.user.userPrincipalName})`                

        let mbApiService = new MBAPIService(Vue.prototype.$appGlobals.appVersion, this.msalService, import.meta.env.VITE_MBAPI_EndPoint, import.meta.env.VITE_MBAPI_AppIDURI);        
        this.$set(this.$root, "mbApiService", mbApiService);        

        this.isAdmin = window.location.pathname.toUpperCase().startsWith('/admin'.toUpperCase());
                               
        if (this.isAdmin) {

          setTimeout(() => {this.loaderVisible = false}, 2000);                          

        } else {
          if (this.$appGlobals.environment === "development" || this.$appGlobals.environment === "staging") {
            let devStateJSON = localStorage.devState;
            if (devStateJSON !== undefined) {          
              let devState = JSON.parse(devStateJSON);
              this.doRestoreState = devState.restoreState;      
              this.doSaveState = devState.saveState;
              this.doRestoreFromLocal = devState.restoreFromLocal === true         
            }        
          }        
          
          this.updateMenuItems();

          this.$watch(function() { return this.$appGlobals.isLargeScreen}  , function (newVal, oldVal) {
            this.updateMenuItems();
          })
          
          this.componentsLeft = this.leftMenuTotalSize;
          this.componentsTop = this.topMenuTotalSize;
          this.componentsRight = this.rightMenuTotalSize;
          this.componentsBottom = this.bottomMenuTotalSize;          

            // Check already running mb under this user.
          this.broadcastChannel = new BroadcastChannel('mappingBrowser');

          this.broadcastChannel.onmessage = (e) => {          
            
            let type = e.data.type;      

            if (type === "info") {
              let userPrincipalName = e.data.userPrincipalName;  
              let environment = e.data.environment               

              if (userPrincipalName === this.user.userPrincipalName) {               
                
                let url = e.data.href;

                // move map etc.
                let urlAccepted = this.processParams(url);

                this.broadcastChannel.postMessage({ type: "alreadyRunning",
                                                    urlAccepted: urlAccepted });              
              }
            }

            if (type === "alreadyRunning") {            
              this.alreadyRunning = true;   
              this.urlAccepted = e.data.urlAccepted;
            }
          };

          this.broadcastChannel.postMessage({ type: "info", 
                                userPrincipalName : this.user.userPrincipalName,
                                environment: this.$appGlobals.environment,
                                href: location.href} );     
          
          setTimeout(this.continueLoad, 2000);                          
        }
      }  
    } 
    catch(e) {
      this.loaderMessage = e.message;
      console.error('Error: ', e.message);
      throw e;
    }    
  },      
  computed: {

    leftMenuItem() {
      return this.menus.menus.find(menu => menu.name === "left");
    },

    topMenuItem() {
      return this.menus.menus.find(menu => menu.name === "top");
    },

    rightMenuItem() {
      return this.menus.menus.find(menu => menu.name === "right");
    },

    bottomMenuItem() {
      return this.menus.menus.find(menu => menu.name === "bottom");
    },

    leftMenuTotalSize() {
      return this.leftMenuShown ? this.recurseTotalMenuSize(this.leftMenuItem) : 0;
    },

    topMenuTotalSize() {
      return this.topMenuShown ? this.recurseTotalMenuSize(this.topMenuItem) : 0;
    },

    rightMenuTotalSize() {
      return this.rightMenuShown ? this.recurseTotalMenuSize(this.rightMenuItem) : 0;
    },

    bottomMenuTotalSize() {
      return this.bottomMenuShown ? this.recurseTotalMenuSize(this.bottomMenuItem) : 0;
    },

    menuItems() {
      let menuItems = {};
    
      let leftItems = this.recurseOpenMenu(this.leftMenuItem);
      leftItems["left"] = this.leftMenuShown;      
      Object.keys(leftItems).forEach(key => {
        menuItems[key] = leftItems[key];
      })

      let topItems = this.recurseOpenMenu(this.topMenuItem);
      topItems["top"] = this.topMenuShown;      
      Object.keys(topItems).forEach(key => {
        menuItems[key] = topItems[key];
      })

  
      let rightItems = this.recurseOpenMenu(this.rightMenuItem);
      rightItems["right"] = this.rightMenuShown;      
      Object.keys(rightItems).forEach(key => {
        menuItems[key] = rightItems[key];
      })

      let bottomItems = this.recurseOpenMenu(this.bottomMenuItem);
      bottomItems["bottom"] = this.bottomMenuShown;      
      Object.keys(bottomItems).forEach(key => {
        menuItems[key] = bottomItems[key];
      })
    
      return menuItems;
    }
  },  
  watch: {
  
    leftMenuTotalSize(size) {    
      gsap.to(this.$data, { duration: this.slideTime, componentsLeft: size});
    },
    topMenuTotalSize(size) {      
      gsap.to(this.$data, { duration: this.slideTime, componentsTop: size});
    },
    rightMenuTotalSize(size) {      
      gsap.to(this.$data, { duration: this.slideTime, componentsRight: size});
    },
    bottomMenuTotalSize(size) {      
      gsap.to(this.$data, { duration: this.slideTime, componentsBottom: size});
    }
  },
	methods: {    

    keyup(e) {      
      if (e.key === "Shift") {
        this.shiftDown = false;
      }
      if (this.$refs.mappingBrowser && !this.loaderVisible) {
        this.$refs.mappingBrowser.globalKeyUp(e);
      }
    },

    keydown(e) {
      if (e.key === "Shift") {
        this.shiftDown = true;
      }
      if (this.$refs.mappingBrowser && !this.loaderVisible) {
        this.$refs.mappingBrowser.globalKeyDown(e);
      }
    },

    mouseup(e) {
      if (this.$refs.mappingBrowser && !this.loaderVisible) {
        this.$refs.mappingBrowser.globalMouseUp(e);
      }

      if(e.button === 3 || e.button === 4) // back or forward
      {          
        // Stop browser back forward.
        e.preventDefault();
      }
    },

    updateAppIcon() {
      let element = document.querySelector("#appIcon");
      let element16 = document.querySelector("#appIcon16");
      let element32 = document.querySelector("#appIcon32");
      let link = element.getAttribute("href");    
      let link16 = element16.getAttribute("href");    
      let link32 = element32.getAttribute("href");    

      if (this.$appGlobals.environment === "development") {            
        let ref = link.replace("favicon", "favicon_dev");
        element.setAttribute("href", ref);                  
        ref = link16.replace("favicon-16x16", "favicon-16x16_dev");
        element16.setAttribute("href", ref);                  
        ref = link32.replace("favicon-32x32", "favicon-32x32_dev");
        element32.setAttribute("href", ref);        
      } else if (this.$appGlobals.environment === "staging") {           
        let ref = link.replace("favicon", "favicon_staging");
        element.setAttribute("href", ref);                  
        ref = link16.replace("favicon-16x16", "favicon-16x16_staging");
        element16.setAttribute("href", ref);                  
        ref = link32.replace("favicon-32x32", "favicon-32x32_staging");
        element32.setAttribute("href", ref);        
      }        
    },

    end() {
      this.$forceUpdate();
    },

    getClientInfo() {
      var unknown = '-';

      // screen
      var screenSize = '';
      if (screen.width) {
          var width = (screen.width) ? screen.width : '';
          var height = (screen.height) ? screen.height : '';
          screenSize += '' + width + " x " + height;
      }

      // browser
      var nVer = navigator.appVersion;
      var nAgt = navigator.userAgent;
      var browser = navigator.appName;
      var version = '' + parseFloat(navigator.appVersion);
      var majorVersion = parseInt(navigator.appVersion, 10);
      var nameOffset, verOffset, ix;

      // Opera
      if ((verOffset = nAgt.indexOf('Opera')) != -1) {
          browser = 'Opera';
          version = nAgt.substring(verOffset + 6);
          if ((verOffset = nAgt.indexOf('Version')) != -1) {
              version = nAgt.substring(verOffset + 8);
          }
      }
      // Opera Next
      if ((verOffset = nAgt.indexOf('OPR')) != -1) {
          browser = 'Opera';
          version = nAgt.substring(verOffset + 4);
      }
      // Legacy Edge
      else if ((verOffset = nAgt.indexOf('Edge')) != -1) {
          browser = 'Microsoft Legacy Edge';
          version = nAgt.substring(verOffset + 5);
      } 
      // Edge (Chromium)
      else if ((verOffset = nAgt.indexOf('Edg')) != -1) {
          browser = 'Microsoft Edge';
          version = nAgt.substring(verOffset + 4);
      }
      // MSIE
      else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
          browser = 'Microsoft Internet Explorer';
          version = nAgt.substring(verOffset + 5);
      }
      // Chrome
      else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
          browser = 'Chrome';
          version = nAgt.substring(verOffset + 7);
      }
      // Safari
      else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
          browser = 'Safari';
          version = nAgt.substring(verOffset + 7);
          if ((verOffset = nAgt.indexOf('Version')) != -1) {
              version = nAgt.substring(verOffset + 8);
          }
      }
      // Firefox
      else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
          browser = 'Firefox';
          version = nAgt.substring(verOffset + 8);
      }
      // MSIE 11+
      else if (nAgt.indexOf('Trident/') != -1) {
          browser = 'Microsoft Internet Explorer';
          version = nAgt.substring(nAgt.indexOf('rv:') + 3);
      }
      // Other browsers
      else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
          browser = nAgt.substring(nameOffset, verOffset);
          version = nAgt.substring(verOffset + 1);
          if (browser.toLowerCase() == browser.toUpperCase()) {
              browser = navigator.appName;
          }
      }
      // trim the version string
      if ((ix = version.indexOf(';')) != -1) version = version.substring(0, ix);
      if ((ix = version.indexOf(' ')) != -1) version = version.substring(0, ix);
      if ((ix = version.indexOf(')')) != -1) version = version.substring(0, ix);

      majorVersion = parseInt('' + version, 10);
      if (isNaN(majorVersion)) {
          version = '' + parseFloat(navigator.appVersion);
          majorVersion = parseInt(navigator.appVersion, 10);
      }

      // mobile version
      var mobile = /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(nVer);

      // cookie
      var cookieEnabled = (navigator.cookieEnabled) ? true : false;

      if (typeof navigator.cookieEnabled == 'undefined' && !cookieEnabled) {
          document.cookie = 'testcookie';
          cookieEnabled = (document.cookie.indexOf('testcookie') != -1) ? true : false;
      }

      // system
      var os = unknown;
      var clientStrings = [
          {s:'Windows 10', r:/(Windows 10.0|Windows NT 10.0)/},
          {s:'Windows 8.1', r:/(Windows 8.1|Windows NT 6.3)/},
          {s:'Windows 8', r:/(Windows 8|Windows NT 6.2)/},
          {s:'Windows 7', r:/(Windows 7|Windows NT 6.1)/},
          {s:'Windows Vista', r:/Windows NT 6.0/},
          {s:'Windows Server 2003', r:/Windows NT 5.2/},
          {s:'Windows XP', r:/(Windows NT 5.1|Windows XP)/},
          {s:'Windows 2000', r:/(Windows NT 5.0|Windows 2000)/},
          {s:'Windows ME', r:/(Win 9x 4.90|Windows ME)/},
          {s:'Windows 98', r:/(Windows 98|Win98)/},
          {s:'Windows 95', r:/(Windows 95|Win95|Windows_95)/},
          {s:'Windows NT 4.0', r:/(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/},
          {s:'Windows CE', r:/Windows CE/},
          {s:'Windows 3.11', r:/Win16/},
          {s:'Android', r:/Android/},
          {s:'Open BSD', r:/OpenBSD/},
          {s:'Sun OS', r:/SunOS/},
          {s:'Chrome OS', r:/CrOS/},
          {s:'Linux', r:/(Linux|X11(?!.*CrOS))/},
          {s:'iOS', r:/(iPhone|iPad|iPod)/},
          {s:'Mac OS X', r:/Mac OS X/},
          {s:'Mac OS', r:/(Mac OS|MacPPC|MacIntel|Mac_PowerPC|Macintosh)/},
          {s:'QNX', r:/QNX/},
          {s:'UNIX', r:/UNIX/},
          {s:'BeOS', r:/BeOS/},
          {s:'OS/2', r:/OS\/2/},
          {s:'Search Bot', r:/(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/}
      ];
      for (var id in clientStrings) {
          var cs = clientStrings[id];
          if (cs.r.test(nAgt)) {
              os = cs.s;
              break;
          }
      }

      var osVersion = unknown;

      if (/Windows/.test(os)) {
          osVersion = /Windows (.*)/.exec(os)[1];
          os = 'Windows';
      }

      switch (os) {
          case 'Mac OS':
          case 'Mac OS X':
          case 'Android':
              osVersion = /(?:Android|Mac OS|Mac OS X|MacPPC|MacIntel|Mac_PowerPC|Macintosh) ([\.\_\d]+)/.exec(nAgt)[1];
              break;

          case 'iOS':
              osVersion = /OS (\d+)_(\d+)_?(\d+)?/.exec(nVer);
              osVersion = osVersion[1] + '.' + osVersion[2] + '.' + (osVersion[3] | 0);
              break;
      }          

      return {
          screen: screenSize,
          browser: browser,
          browserVersion: version,
          browserMajorVersion: majorVersion,
          mobile: mobile,
          os: os,
          osVersion: osVersion,
          cookies: cookieEnabled,       
      };
    },

    inflateProject(compressedProject) {
      let binary = atob(compressedProject);

      var rawLength = binary.length;
      var array = new Uint8Array(new ArrayBuffer(rawLength));

      for(let i = 0; i < rawLength; i++) {
        array[i] = binary.charCodeAt(i);
      }
      
      return pako.inflate(array, {to: "string"});  
    },    

    whatsNewHidden() {
      this.showWhatsNew = false;
     },

    async exportScreenshot() {      
      await this.$refs.mappingBrowser.downloadScreenshot();
      this.projectMenuOpen = false;
    },

    async exportPresented(args) {
      await this.$refs.mappingBrowser.exportPresentedMap(args);
      this.projectMenuOpen = false;
    },    

    processParams(href) {
      let params = new URL(href).searchParams;      
      let x = params.get("x");
      let y = params.get("y");
      let scale = params.get("scale");
      let centerAccepted = false;
      let scaleAccepted = false;

      let center;

      if (x && y) {                          
        center = this.$refs.mappingBrowser.getAgsPointByXy(x, y);        
      }

      if (center || scale) {             

        this.$refs.mappingBrowser.goto({
          center: center,
          scale: scale
        })

        if (center) {
          this.$refs.mappingBrowser.pinLocation(center);
        }

        return true;        
      }      

      return false;
    },   

    async initBackendAuthentication() {

      axios.interceptors.request.use(async request => {        

        if (request.url.startsWith(import.meta.env.VITE_ARCGIS_URL)) {          
          
          if (!request.headers.Authorization) {
            var accessToken = await this.msalService.getToken({scopes: [import.meta.env.VITE_MBAPI_AppIDURI]})                              
            request.headers.Authorization = "Bearer " + accessToken;
          }          
        }                

        return request;
      }, function (error) {  
        console.error(error);
        return Promise.reject(error);        
      });                 
    },

    async continueLoad() {
       if (this.alreadyRunning) {
        this.broadcastChannel.close();
        this.loaderSpinVisible = false;
        if (this.urlAccepted) {
          this.loaderMessage = "Link accepted in the Mapping Browser you already have open.  Please close this tab or window, and use the one you already have open." 
        }
        else {        
          this.loaderMessage = "Mapping Browser is already open.  Please close this tab or window, and use the one you already have open." 
        }
      } else {

        try {
      
          this.initBackendAuthentication();
          this.networkInterceptors = new NetworkInterceptors(this.msalService);          
          this.loaderMessage = "Elves creating maps..." 

          let userConfig = null;
          this.networkInterceptors.start();
          
          try {
            userConfig = await this.mbApi.getConfig();           
          }
          catch (e) {            
            console.error(e);
            if (e.message.includes("client too old")) {
              this.loaderSpinVisible = false;
              this.loaderMessage = "Mapping Browser has been updated.  Please refresh using Ctrl + F5.  if not successful, wait and try again in 30 mins."
              return;
            }            

            if (e.message.includes("down for maintenance")) {
              this.loaderSpinVisible = false;
              this.loaderMessage = "Mapping Browser is currently down for maintenance. Please wait and retry later." 
              return;
            }      
            
            throw e;
          }

          this.arcGISBaseUrl = import.meta.env.VITE_ARCGIS_URL;          
          this.basemaps = userConfig.basemaps.map(basemap => {
            return {
              copyright: basemap.copyright,
              description: basemap.description,
              id: basemap.id.toString(),
              opacity: 1,
              overviewEnabled: basemap.overviewEnabled,
              thumbnail: basemap.thumbnail,
              title: basemap.title,
              arcGISPath: basemap.arcGISPath,
              extendedScalesUrl: basemap.id === 4 ? "https://intgis.norfolk.gov.uk/arcgis/rest/services/internal/mm_schema9_ext_scales_fgdb/MapServer" : "",
              visible: false
            }
          })

          if (this.basemaps.filter(basemap => basemap.visible).length === 0) {
            let id = this.$appGlobals.environment === "development" || this.$appGlobals.environment === "staging" ? "22" : "21";
            let basemap = this.basemaps.find(basemap => basemap.id === id);
            basemap.visible = true;          
          }
                        
          console.log("CONFIG:"); 
          console.log(userConfig); 

          this.layers = userConfig.layers;                    
          this.mapExtent = userConfig.extent;
          this.helpUrlBase = userConfig.helpUrl;
          this.useExtendedScales = userConfig.roles.indexOf("ExtendedScales") !== -1;          
          this.canCreateLayers = userConfig.canCreateLayers;

          if (this.doRestoreFromLocal && localStorage.project) {
            let project = JSON.parse(localStorage.project);
                        
            this.$root.projectLastUpdated = new Date();
            this.$root.sessionProject = project;                        
          } else {
                            
            let compressedProject = userConfig.sessionProject ? userConfig.sessionProject.payload : null;          

            if (compressedProject) {                         
              let projectJSON = this.inflateProject(compressedProject);      
              let project = JSON.parse(projectJSON);                      
                                    
              let utcDate = Moment.utc(userConfig.sessionProject.lastUpdateTime).toDate();                        
              this.$root.projectLastUpdated = utcDate;            
              this.$root.sessionProject = project;                        
            }                     
          }

          if (localStorage.deviceSettings) {
            this.deviceSettings = JSON.parse(localStorage.deviceSettings);
          } 
      
          for (let search of userConfig.searches) {
            search.layerId = search.layerId ? search.layerId.toString() : search.layerId
          }

          this.searches = userConfig.searches;
          
          this.user.roles = userConfig.roles;
          this.user.userId = userConfig.userId          

          this.arcGISTokens = userConfig.arcGISTokens;          
          this.bookmarksThumbNailSourceUrl = userConfig.bookmarkThumbnailArcGISPath;                            

          /*let locatorHubToken; locatordisabled
          
          try {
            locatorHubToken = await this.mbapiService.getLocatorHubToken();                
          }
          catch (e) {
            console.error('Error getting locatorhub token: ', e.message);            
          }
          
          this.networkInterceptors.locatorHubToken = locatorHubToken;*/
                    
          this.networkInterceptors.arcGISTokens = this.arcGISTokens;
          this.networkInterceptors.layersConfig = this.layers;
          
          let locatorUrls = userConfig.searches.filter(search => search.locatorUrl).map(search => search.locatorUrl);
          this.networkInterceptors.locatorUrls = locatorUrls;          

          this.tokenRefreshInverval = setInterval(async () => {            
            let locatorHubToken = await this.mbApi.getLocatorHubToken();           
            this.networkInterceptors.locatorHubToken = locatorHubToken;
          }, this.tokenTimeoutMins * 1000 * 60)
  
          this.isLoaded = true;      
        }
        catch(e) {
          this.loaderMessage = e.message;
          this.loaderSpinVisible = false;
          console.error('Error: ', e.message);
          throw e;
        }                    
      }
    },

    downloadProject() {            

      let project;

      if (this.$root.sessionProject) {
        project = this.$root.sessionProject;        
      } else {
        project = this.getState("project");
      }
      
      this.$refs.mappingBrowser.downloadProject(project);
      this.projectMenuOpen = false;
    },

    initCancelled() {
      this.loaderSpinVisible = false;
      this.loaderMessage= "Refresh to retry.";
    },

    updateMenuItems() {   

      let open = this.menuItems["properties"];
      
      if (open) {
        this.closeMenuItem("properties");
      }
              
      let leftMenu = this.getMenuItem("left");
      let rightMenu = this.getMenuItem("right");

      if (this.$appGlobals.isLargeScreen) {
        let index = leftMenu.menus.findIndex(subMenu => subMenu.name === "properties");
        if (index !== -1) {
          let menu = leftMenu.menus[index];
          leftMenu.menus.splice(index, 1);          
          rightMenu.menus.push(menu);          
        }                
      } 
      else 
      {
        let index = rightMenu.menus.findIndex(subMenu => subMenu.name === "properties");
        if (index !== -1) {
          let menu = rightMenu.menus[index];
          rightMenu.menus.splice(index, 1);
          leftMenu.menus.push(menu);
        }                
      }

      if (open) {
        this.openMenuItem("properties");
      }
    },

    signOut(){
      this.msalService.logout();
    },

    onBasemapChange(payload){
      let basemap = payload.basemap;      
      basemap.visible = payload.visible        
      basemap.opacity = payload.opacity;
    },
      
    restoreStateEnabled(restore) {
      this.doRestoreState = restore;
      this.saveDevState();
    },

    saveStateEnabled(save) {      
      this.doSaveState = save;
      this.saveDevState();
    },   
    
    restoreFromLocalEnabled(save) {      
      this.doRestoreFromLocal = save;
      this.saveDevState();
    },        

    async restoreSession() {
      if (this.doRestoreState) {        

        if (this.$root.sessionProject || this.deviceSettings) {
          this.$appGlobals.isRestoringState = true;                              
          this.loaderMessage = "Maps ready. Restoring session..."                       

          try {
            if (this.$root.sessionProject) {         
              console.log("RESTORING PROJECT:");
              console.log(this.$root.sessionProject);
              await this.restoreState("project", this.$root.sessionProject.version, null, this.$root.sessionProject);                                    
            }
            if (this.deviceSettings) {
              await this.restoreState("device", this.deviceSettings.version, null, this.deviceSettings);                
            }
          } catch(e) {            
            console.error(e);
            this.initRestoreFailed = true;
            this.loaderSpinVisible = false;
            this.loaderMessage= "Could not restore session...*Refresh to retry*.  " + e;
          }
        }             
      }                                  
    },

    restoreFinished() {
      if (!this.initRestoreFailed) {
        console.log("Session restored.");               
        this.$refs.mappingBrowser.addEvent("open", JSON.stringify(this.clientInfo));                
        this.$root.syncQueue = new QueuePromise({
          concurrent: 1,
          interval: 0,
          start: false
        });

        this.$root.syncEnabled = true;             
        window.addEventListener('beforeunload', this.beforeUnload);      
        this.$appGlobals.isRestoringState = false;    
                
        this.processParams(location.href);        

        if (this.showWhatsNew) {
          this.$refs.whatsNewDialog.show();
        }

        this.loaderVisible = false;        
      }
    },

    async onLoaded() {                  

      // wait for full view to be rendered (in reaction to mappingbrowser.loaded becoming TRUE), so that
      // we have full refs available.
      queueMicrotask(async () => {
        this.networkInterceptors.executeAfterLastResponse(this.restoreFinished, 2000);                            
        if (this.shiftDown && (this.user.roles.indexOf("Developer") !== -1)) {
          this.doRestoreState = false;
          this.doSaveState = false;
        }
        await this.restoreSession();        
      });                        
    },

    beforeUnload(event) {                

      if (this.$root.syncEnabled) {
        this.$root.syncEnabled = false;        
      }

      this.mbApi.addEvent("close");

      if (this.broadcastChannel) {
        this.broadcastChannel.close();
      }             
    },

    async SyncProject(save) {                 

      if (this.$root.syncEnabled) {                

        try {                               
          if (await this.refreshState()) {                        
            if (save){                          
              await this.saveState();                                        
            }          
          }          
        }
        catch (e) {
          console.error(e);          

          if (e.message.includes("client too old")) {
            this.waitSpinner = false;
            this.waitDialogMessage = "Mapping Browser has been updated, please refresh tab or window to continue using."
            this.$refs.waitDialog.show();    
            this.$root.syncEnabled = false;    
            return;
          } else if (e.message.includes("error getting project state")){
            this.waitSpinner = false;
            this.waitDialogMessage = e.message;
            this.$refs.waitDialog.show();    
            this.$root.syncEnabled = false;    
            return;
          } 
          else  
          {
            this.$root.syncEnabled = false;
            
            if (e.message.includes("down for maintenance")) {
              this.waitDialogMessage = "Mapping Browser is currently down for maintenance.  Retrying..."
            } else {
              this.waitDialogMessage = "No internet or connection is unavailable. Retrying...	"
            }

            this.waitSpinner = true;
            this.$refs.waitDialog.show();       

            let exit = false;
            while (!exit) {
              await this.sleep(1000);          
              try
              {                
                if (await this.refreshState()) {               
                  if (save) {                    
                    await this.saveState();                       
                  }                  
                  this.$refs.waitDialog.hide();    
                  this.$root.syncEnabled = true;            
                }              
                exit = true;                               
              } catch (e) {              
                console.error("sync failed");
                if (e) {              
                  console.error(e);
                }

                if (e.message.includes("client too old")) {      
                  this.waitSpinner = false;
                  this.waitDialogMessage = "Mapping Browser has been updated, please refresh tab or window to continue using."                              
                  this.$root.syncEnabled = false;    
                  exit = true;
                }
                else {
                  this.waitSpinner = true;
                  if (e.message.includes("down for maintenance")) {                    
                    this.waitDialogMessage = "Mapping Browser is currently down for maintenance.  Please wait..."
                  } else {                    
                    this.waitDialogMessage = "No internet or connection is unavailable. Retrying...	"
                  }
                }
              }            
            }                         
          }
        }                 
      }
    },
        
    async refreshState() {
      if (this.doRestoreState && this.$root.sessionProject) {                        
        let response = await this.mbApi.getSessionProject(this.$root.projectLastUpdated);                

        if (response) {
          // Project is more recent than last saved.
          console.log("Project more recent");

          let compressedProject = response.payload;        
          let projectJSON = this.inflateProject(compressedProject);        
          let project = JSON.parse(projectJSON);                                  
          
          if (project) {            
                                        
            this.$root.syncEnabled = false;                                                              
            this.waitSpinner = false;
            this.waitDialogMessage = "Project changed elsewhere.   Please refresh to continue using Mapping Browser."
            this.$refs.waitDialog.show();                     
            return false;            
          }
        }        
      }  

      return true;
    },

    saveDevState() {
      let devState = {
        restoreState: this.doRestoreState,        
        saveState: this.doSaveState,
        restoreFromLocal: this.doRestoreFromLocal
      }

      let devStateJSON = JSON.stringify(devState);
      localStorage.devState = devStateJSON;
    },
   
    async saveState() {                                            
     
      if (this.doSaveState) {
        
        this.deviceSettings = await this.getState("device");             
        let settingsJSON = JSON.stringify(this.deviceSettings);                  
        localStorage.deviceSettings = settingsJSON;                 

        let project;
           
        try {          
          project = await this.getState("project");                 
        } catch (e) {          
          throw new Error("error getting project state.  " + e.message);          
        }
        
        if (this.$appGlobals.environment === "development" || this.$appGlobals.environment === "staging") {
          // console.log("PROJECT:")                 
          // console.log(project);
        }

        let lastUpdated;

        try {                            
          lastUpdated = await this.mbApi.saveSessionProject(project);                                                                                                                    
        } catch (e)           {
          console.error("failed to save project");
          throw e;
        }
        
        this.$root.sessionProject = project;
        this.$root.projectLastUpdated = lastUpdated;                
      }                     
    },     
    
    getMenuItemRecurse(currentMenu, name) {
  
      if (currentMenu.name === name) {
        return currentMenu;
      }
      
      if (currentMenu.menus) {
        for (let childMenu of currentMenu.menus) {
          let menu = this.getMenuItemRecurse(childMenu, name);
          if (menu) {
            return menu;
          } 
        }
      }
    },

    getMenuItem(name) {
      return this.getMenuItemRecurse(this.menus, name)
    },

    getParentMenuItemRecurse(currentMenu, name) {
      
      if (currentMenu.menus) {
        for (let childMenu of currentMenu.menus) {
          if (childMenu.name === name) {
            return currentMenu;
          } else {
            let menu = this.getParentMenuItemRecurse(childMenu, name);
            if (menu) {
              return menu;
            }
          }
        }
      }
    },

    getParentMenuItem(name) {
      return this.getParentMenuItemRecurse(this.menus, name);
    },

    openMenuItem(itemName) {      

      switch (itemName) {
        case "left":
          this.leftMenuShown = true;
          break;          
        case "top":
          this.topMenuShown = true;
          break;          
        case "right":
          this.rightMenuShown = true;
          break;          
        case "bottom":
          this.bottomMenuShown = true;
          break;                  
        default:

          let parentItem = this.getParentMenuItem(itemName);
          parentItem.openItem = itemName;
          break;
      }     
    },

    closeMenuItem(itemName) {      

      switch (itemName) {
        case "left":
          this.leftMenuShown = false;
          break;          
        case "top":
          this.topMenuShown = false;
          break;          
        case "right":
          this.rightMenuShown = false;
          break;          
        case "bottom":
          this.bottomMenuShown = false;
          break;                  
        default:

          let parentItem = this.getParentMenuItem(itemName);
          if (parentItem.openItem === itemName)
          {      
            parentItem.openItem = null;       
          }

          break;
      }
    },   

    recurseTotalMenuSize(menu) {      
    
      let childSize = 0;

      if(menu.openItem) {
        let childMenu = menu.menus.find(child => child.name === menu.openItem);
        childSize = this.recurseTotalMenuSize(childMenu); 
      } 

      return menu.size + childSize;
    },   

    recurseOpenMenu(menu) {      
  
      let menuItems = {};
      menuItems[menu.name] = false;

      if(menu.menus) {        
        menu.menus.forEach(childMenu => {
          let items = this.recurseOpenMenu(childMenu);
            Object.keys(items).forEach(itemName => {
            menuItems[itemName] = (menu.openItem === itemName);
          })
        });        
      }
      return menuItems;
    },   

		setFullScreen(isFullScreen) {
			
			if (isFullScreen) {
				this.leftMenuShown = false;
				this.topMenuShown = false;
				this.rightMenuShown = false;
				this.bottomMenuShown = false;
			} else {
				this.leftMenuShown = true;
				this.topMenuShown = true;
				this.rightMenuShown = true;
				this.bottomMenuShown = true;
			}

			this.isFullScreen = isFullScreen;
		},    
  }, 
};

</script>

<style lang="scss" scoped>

#app
{
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;

  #projectMenu {
    z-index: 9999
  }

  .blurred {
    -webkit-filter: blur(2px);
    -moz-filter: blur(2px);
    -o-filter: blur(2px);
    -ms-filter: blur(2px);
    filter: blur(2px);
  }
}

</style>
